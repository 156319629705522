<template>
  <div class="footer-container">
    <div class="footer-left">
      <router-link to="/tsAndCs">Terms Of Service</router-link>
      <router-link to="/PrivacyPolicy">Privacy Policy</router-link>
    </div>
    <div class="footer-center">
      <section>
        <img src="../../public/images/logo-two.svg" alt="" />
      </section>
    </div>
    <div class="footer-right">
      <a href="https://www.instagram.com/earth_to_oven/?hl=en" target="_blank" aria-label="instagram"><font-awesome-icon :icon="['fab', 'instagram']" size="2x" class="icon" /></a>
      <a href="https://www.facebook.com/earthtoovenltd/" target="_blank" aria-label="facebook"><font-awesome-icon :icon="['fab', 'facebook']" size="2x" class="icon" /> </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "FootItem",
};
</script>

<style lang="scss">
.footer-container {
  display: flex;
  justify-content: space-evenly;
  height: 15vh;
  padding: 2rem;
  align-items: center;
  background: var(--nav-background-fade);
  .footer-left {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-direction: column;
    margin: 0;
    a {
      margin: 5px;
      text-decoration: none;
      color: var(--text-colour);
    }
  }
  .footer-right {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-direction: row;
    margin: 1rem;
    a {
      margin: 5px;
      text-decoration: none;
      color: var(--text-colour);
    }
  }
  .footer-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      width: 15rem;
      box-shadow: none;
    }
  }
}
@media only screen and (orientation: portrait) {
  .footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    margin: 2px;
    .footer-left {
      display: flex;
      flex-direction: column;
      width: 25%;
      align-items: center;
      text-align: center;
      margin: 5px;
      a {
        font-size: x-small;
      }
    }
    .footer-center {
      img {
        width: 5rem;
        box-shadow: none;
      }
    }
    .footer-right {
      display: flex;
      flex-direction: row;
      width: 25%;
      align-items: center;
      justify-content: center;
      margin: 5px;
      svg {
        width: 1rem;
      }
    }
  }
}
</style>
