<template>
  <section class="home-container">
    <!-- <section class="home-header"></section> -->
    <section class="home-content">
      <img src="../../public/images/logo.svg" alt="eto Logo" />
    </section>
    <section class="testimonials">
      <Carousel />
    </section>
    <section class="content-img">
      <div class="block-one">
        <img src="../../public/images/pop-up1.jpeg" alt="food at pop up restaurant " />
        <div class="inner-one">
          <img src="../../public/images/customer4.jpeg" alt="happy customer with flatbread" />
          <img src="../../public/images/eto-trailer2.jpeg" alt="e.t.o trailer" />
        </div>
      </div>
      <div class="block-two">
        <img src="../../public/images/flatbread5.jpeg" alt="an e.t.o flatbread" class="portrait" />
        <div class="inner-two">
          <img src="../../public/images/alfie-joey1.jpeg" alt="Alfie and Joey at Pop up restaurant in Bangkok" />
          <img src="../../public/images/awards.svg" alt="British Street food award 2022" class="award"/>
        </div>
        <img src="../../public/images/flatbread6.jpeg" alt="flatbread cooking " class="portrait-addition" />
      </div>
      <div class="block-three">
        <img src="../../public/images/alfie1.jpeg" alt="alfie with a large pumpkin" />
        <img src="../../public/images/eto-trailer8.jpeg" alt="e.t.o trailer" />
      </div>
    </section>
  </section>
</template>

<script>
import Carousel from "../components/Carousel.vue";
export default {
  components: {
    Carousel,
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss">
.home-container {
  display: flex;
  flex-direction: column;

  .home-header {
    display: flex;
    // padding: 5rem;
  }

  .testimonials {
    padding: 2rem;
  }

  .home-content {
    display: flex;
    height: 80vh;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    background-image: url(../../public/images/flatbread4.jpeg);
    background-color: var(--tertiary-colour);
    background-repeat: no-repeat;
    background-blend-mode: lighten;
    background-position: center;
    // height: 70vh;
    img {
      box-shadow: none;
    }
  }

  .content-img {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--background-fade);
    min-height: 45vh;
    padding: 0.5rem;
    overflow: hidden;
    .block-one {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 40vh;
      img {
        width: 22rem;
        max-height: 10rem;
        object-fit: cover;
        margin: 1rem;
        border-radius: 5px;
        transition: all 500ms ease-in-out;
        &:hover {
          scale: 1.1;
        }
      }
      .inner-one {
        display: flex;
        flex-direction: row;
        img {
          max-height: 10rem;
          width: 10rem;
          object-fit: cover;
          transition: all 500ms ease-in-out;
          &:hover {
            scale: 1.1;
          }
        }
      }
    }
    .block-two {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      min-height: 40vh;
      img {
        width: 22rem;
        height: 22rem;
        object-fit: cover;
        margin: 1rem;
        border-radius: 5px;
        transition: all 500ms ease-in-out;
        &:hover {
          scale: 1.1;
        }
      }
      .inner-two {
        display: flex;
        flex-direction: column;
        .award {
          box-shadow: none;
        }
        img {
          max-height: 10rem;
          width: 10rem;
          object-fit: cover;
          transition: all 500ms ease-in-out;
          &:hover {
            scale: 1.1;
          }
        }
      }
    }
    .block-three {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 30vh;
      img {
        width: 20rem;
        height: 10rem;
        object-fit: cover;
        object-position: 50% 20%;
        margin: 1rem;
        border-radius: 5px;
        transition: all 500ms ease-in-out;
        &:hover {
          scale: 1.1;
        }
      }
    }
  }
}
@media only screen and (orientation: portrait) {
  .home-container {
    .home-content {
      height: 70vh;
      img {
        width: 20rem;
        height: 20rem;
      }
    }
    .content-img {
      min-width: 10vw;
      .block-one {
        display: none;
      }
      .block-two {
        .portrait {
          display: none;
        }
        .portrait-addition {
          width: 10rem;
        }
      }
      .block-three {
        display: none;
      }
    }
  }
}
</style>
