<template lang="">
  <div class="shadow-navbar">
    <nav :class="isScrolled ? 'nav-shadow' : 'nav'">
      <router-link to="/" class="navTag">Welcome</router-link>
      <router-link to="/about" class="navTag">Our Story</router-link>
      <router-link to="/getintouch" class="navTag">Get in Touch</router-link>
      <!-- <router-link to="/shop" class="navTag">Shop</router-link> -->
      <router-link to="/services" class="navTag">Services</router-link>
      <!-- <router-link to="/meals" class="navTag">Meal Prep</router-link> -->
      <!-- <router-link to="/basket" class="navTag" -->
      <!-- ><font-awesome-icon :icon="['fas', 'basket-shopping']" class="icon" /> <span v-if="cartQuantity">{{ cartQuantity }}</span></router-link -->
      <!-- > -->
    </nav>
  </div>

  <div class="nav-collapse" @click="handleNavClick">
    <p>=</p>
    <div :class="navbarOpen ? 'show-nav' : 'hide-nav'"></div>
  </div>
  <nav @click="handleNavClick" class="portrait-nav">
    <router-link to="/" class="navTag">Welcome</router-link>
    <router-link to="/about" class="navTag">Our Story</router-link>
    <router-link to="/getInTouch" class="navTag">Get in Touch</router-link>
    <!-- <router-link to="/shop" class="navTag">Shop</router-link> -->
    <router-link to="/services" class="navTag">Services</router-link>
    <!-- <router-link to="/meals" class="navTag">Meal Prep</router-link> -->
    <!-- <router-link to="/basket" class="navTag" -->
    <!-- ><font-awesome-icon :icon="['fas', 'basket-shopping']" class="icon" /> <span v-if="cartQuantity">{{ cartQuantity }}</span></router-link -->
    <!-- > -->
    <button @click.stop.prevent="handleNavClick">CLOSE</button>
  </nav>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "NavBar",
  data() {
    return {
      navbarOpen: false,
      isScrolled: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  computed: {
    ...mapGetters(["cartQuantity"]),
  },

  methods: {
    handleNavClick() {
      this.navbarOpen = !this.navbarOpen;
      if (this.navbarOpen) {
        document
          .querySelector(".portrait-nav")
          .style.setProperty("display", "flex");
        setTimeout(() => {
          document
            .querySelector(".portrait-nav")
            .style.setProperty("transform", "translateX(0)");
        }, 100);
      } else {
        document
          .querySelector(".portrait-nav")
          .style.setProperty("transform", "translateX(500px)");
        setTimeout(() => {
          document
            .querySelector(".portrait-nav")
            .style.setProperty("display", "none");
        }, 500);
      }
    },
    handleScroll() {
      if (window.scrollY > 50) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.shadow-navbar {
  padding-right: 3rem;

  .nav {
    position: absolute;
    width: 100%;
    right: 0.1mm;
    padding: 1.5rem;
    display: flex;
    flex-direction: row;
    // justify-content: right;
    justify-content: flex-end;
    // background-color: var(--background-colour);
    transition: all 1000ms ease-in-out;
    z-index: 10;
    .navTag {
      text-decoration: none;
      color: var(--text-colour);
      padding: 0rem 1rem;
    }
  }

  .nav-shadow {
    display: flex;
    width: 100%;
    right: 0.1mm;
    flex-direction: row;
    justify-content: right;
    padding: 1rem;
    // padding-right: 3rem;
    justify-content: flex-end;
    display: flex;
    box-shadow: 0px 1px 5px var(--action-colour);
    position: fixed;
    background: var(--nav-background-fade);
    transition: all 1000ms ease-in-out;
    z-index: 10;
    opacity: 5;
    .navTag {
      text-decoration: none;
      color: var(--text-colour);
      padding: 0rem 1rem;
    }
  }
}
.portrait-nav {
  display: none;
}

.nav-collapse {
  display: none;
}

#nav .active-link {
  font-weight: bold;
  border-bottom: 2px solid var(--action-colour);
}

@media only screen and (orientation: portrait) {
  .nav {
    display: none;
  }
  .shadow-navbar {
    display: none;
  }

  .hide-nav {
    display: none;
  }

  .portrait-nav {
    right: 0;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    background: var(--nav-background-fade);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10;
    transform: translateX(500px);
    transition: all 500ms ease-in-out;
    a,
    button {
      all: unset;
      font-family: Apex mk3-regular;
      text-align: right;
      margin: 1rem;
      padding: 2rem 0.5rem 2rem 8rem;
      width: 13rem;
      text-decoration: none;
      box-shadow: var(--box-shadow);
      background-color: var(--background-colour);
      border-radius: 5px;
      border: none;
    }
  }

  .nav-collapse {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: flex-end;
    background: var(--nav-background-fade);
    align-items: center;
    height: 3rem;
    right: 0.5rem;
    z-index: 10;
    p {
      font-size: xx-large;
      // position: absolute;
      padding-right: 1rem;
    }
    .show-nav {
      div {
        // position: absolute;
        z-index: 100;

        right: 2rem;
        // align-items: center;
        display: flex;
        flex-direction: column;
        // background-color: var(--background-colour);
        box-shadow: 0px 5px 10px var(--action-colour);
        border-radius: 5px;
      }
    }
  }
}
</style>
