<template>
  <section class="home-testimonials">
    <h2>Testimonials</h2>
    <div>
      <button @click.stop.prevent="prevSlide" aria-label="previous slide">
        <font-awesome-icon :icon="['fas', 'arrow-left']" />
      </button>
      <div
        v-for="(review, index) in reviews"
        :key="index"
        :class="{
          'testimony-slide-active': index === activeSlide,
          testimony: index != activeSlide,
        }"
      >
        <h2>{{ review.author_name }}</h2>
        <!-- <div class="rating">
          <span v-for="value in review.rating" :key="value">
            <font-awesome-icon :icon="['fas', 'star']" />
          </span>
        </div> -->
        <p>{{ review.review }}</p>
      </div>
      <button @click.stop.prevent="nextSlide" aria-label="next slide">
        <font-awesome-icon :icon="['fas', 'arrow-right']" />
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: "CarouselItem",
  components: {},

  data() {
    return {
      reviews: [
        {
          author_name: "Emma Pym",
          review:
            "We are so grateful to Earth to Oven for catering our wedding party. The food service ran sooo smoothly and it’s all thanks to the team’s hard work. The menu was also absolutely delicious and people are still talking about it when we speak with them about the evening!!… I’m certain the curry sauce chicken flatbread will always remain one of the best things I’ve ever eaten! +++ points for the team being just the loveliest, smiliest bunch! We’ll definitely seek them out at another event soon!",
        },
        {
          author_name: "David Jarvis",
          review:
            "We ordered the chicken flatbread and the venison flatbread along with the macaroni cheese bites this evening in Liss and the food was incredible. Unbelievable taste and lovely service will definitely be back again next week.",
        },
        {
          author_name: "African Fisherman",
          review:
            "E.T.O was at the pumpkin event, the staff were super friendly when I ordered my coffee. It was delicious after spending kids if time in the cold. The coffee was fantastic 👌🏻👌🏻",
        },
        {
          author_name: "C Martelosso",
          review:
            "Don’t even know how to put it, that’s how good it was. You just got to try it.",
        },
        {
          author_name: "James Goulsbra",
          review:
            "Words will never be able to truly describe the food this amazing company put together. If you want to experience food with awesome flavours and a truly fantastic taste. Then go and find where Alfie and his team are popping up and get yourself something off their menu. Using local ingredients and putting together remarkable food, they are taking street food to the next level. Go check them out.",
        },
        {
          author_name: "Henry Nash",
          review:
            "Alfie and his team provided the food at our wedding party as we wanted something a bit different. His food is incredible - his team have a talent for creating variety from locally sourced produce. People raved about the food - and are still complimenting it now!",
        },
        {
          author_name: "Sophia Jenkins",
          review:
            "The food we ate from here yesterday was absolutely delicious and I can't wait to come back and try some more of the flavour combinations. Everyone in our family of 4 really enjoyed their flatbreads and the service was so friendly and sincere. Highly recommend!",
        },
      ],
      activeSlide: 0,
    };
  },
  methods: {
    prevSlide() {
      if (this.activeSlide === 0) {
        this.activeSlide = this.reviews.length - 1;
        // console.log("Previous slide is:", this.activeSlide);
      } else {
        this.activeSlide -= 1;
      }
    },
    nextSlide() {
      if (this.activeSlide === this.reviews.length - 1) {
        this.activeSlide = 0;
        // console.log("Next slide is:", this.activeSlide);
      } else {
        this.activeSlide += 1;
      }
    },
  },
  // async created() {
  //   try {
  //     const response = await fetch("http://localhost:4242/reviews");
  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }
  //     const data = await response.json();
  //     this.reviews = data.result.reviews;
  //     // console.log("data fetched and pushed to reviews array", this.reviews);
  //   } catch (error) {
  //     console.log("Fetch error: ", error);
  //   }
  // },
};
</script>

<style lang="scss" scoped>
.home-testimonials {
  display: flex;
  margin: 2rem;
  flex-direction: column;
  min-height: 40vh;
  // background: var(--background-fade);
  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    button {
      height: 20rem;
      background: var(--background-colour);
      color: var(--text-colour);
      padding: 1rem;
      border: none;
      border-radius: 5px;
      margin: 1rem;
      cursor: pointer;
      transition: all 500ms ease-in-out;
    }
    button:hover {
      box-shadow: var(--box-shadow);
      scale: 1.1;
    }
    .testimony {
      display: none;
    }
    .testimony-slide-active {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 80%;
      height: 100%;
      padding: 20px;
      margin-bottom: 20px;
      border-radius: 5px;
      // box-shadow: var(--box-shadow);
      margin: 3rem 3rem;
      transition: all 1000ms ease-in-out;
      cursor: pointer;
      h2 {
        color: #1a0dab;
        font-size: 20px;
      }
      img {
        width: 2rem;
        box-shadow: none;
      }
      p {
        color: #333;
        font-size: 14px;
        margin: 0;
      }
      .rating {
        color: var(--text-colour);
        padding: 1rem;
        scale: 1.1;
      }
    }
  }
  .testimony-slide-active:hover {
    // border: 1px solid #ddd;
    // cursor: url('../../public/images/cabbage.svg'), auto;
    box-shadow: var(--box-shadow);
  }
}
@media only screen and (orientation: portrait) {
  .home-testimonials {
    height: 65vh;
    margin: 0;
    div {
      button {
        padding: 0.5rem;
        margin: 0.5rem;
      }
      .testimony-slide-active {
        width: 90%;
        padding: 10px;
        margin: 0;

        .rating {
          padding: 0.5rem;
        }
      }
    }
  }
}
</style>
